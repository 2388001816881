/* CSS Document */

.slick-outer {
	position: relative;
	
	.slick-wrapper {}
	
	.slick-controls {
		position: absolute;
		background: #fff;
		padding: 10px 5px;
		bottom: 0;
		right: 0;
		
		.control {
			padding: 10px 15px;
			display: inline-block;

			&::before {
				@include icon-font;
				content: "\61";
				font-size: (28rem / 16);
			}
			
			&.control-next::before {
				content: "\62";
			}
			
			&:hover {
				color: $color-green;
			}
		}
	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		.slick-controls {
			background: none;
			padding: 0;
			bottom: 50%;
			left: 0;
			transform: translateY(50%);
			
			.control {
				padding: 15px;
				float: left;
				&::before {
					content: "\68";
				}
				&.control-next {
					float: right;
					&::before {
						content: "\69";
					}
				}
			}
		}
	}
	
}