/* Section: Contact */

.contact {
	
	.row-intro {
		margin-bottom: 2rem;
	}
	
	h3 {
		position: relative;
		font-size: (20rem / 16);
		margin-top: 3rem;
		margin-bottom: 1rem;
		font-weight: 700;
		color: #000;
		&::before {
			position: absolute;
			content: '';
			background: url('../images/heart-green.png') center center no-repeat;	
			width: 33px;
			height: 26px;
			top: -8px;
			left: -16px;
			z-index: -1;
		}
	}
	
	.get-in-touch {
		position: relative;
		padding: 40px 0 10px 0;
		
		h3 {
			margin: 0 0 0.5rem 0;
			&::before {
				display: none;
			}
		}
		p {
			font-size: (35rem / 16);
			font-weight: 500;
		}
		
		a:hover {
			color: #000;
		}
		
		&::before {
			position: absolute;
			display: block;
			content: '';
			background: $color-green-light;
			top: 0;
			left: -1000px;
			right: 0;
			border-radius: 0 200px 200px 0;
			bottom: 0;
			z-index: -1;
		}
	}
	
	.col-map {	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		h3 {
			&::before {
				left: -13px;
			}
		}
		
		.get-in-touch {
			p { font-size: (25rem / 16); }
		}
		
		.col-map {
			padding: 40px 0 0 0;
			margin-bottom: -10rem;
		}
	}
	
}