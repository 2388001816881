/* News article */

.news-article {
	
	.container {
		position: relative;
	}
	
	.banner-image {
		height: 660px;
		background: center center no-repeat;
		background-size: cover;
	}
	
	.news-link {
		position: absolute;
		display: block;
		transform-origin: 50% 50%;
		top: 40em;
		font-size: (14rem / 16);
		line-height: 20px;
		z-index: 4;
		
		i {
			color: #676767;
			line-height: 20px;
		}
		
		&.news-link-prev { 
			left: 25px; 
			transform: translateX(-50%) rotate(90deg);
			i {
				padding-right: 1em;
			}
		}
		&.news-link-next { 
			right: 25px; 
			transform: translateX(50%) rotate(90deg);
			i {
				padding-left: 1em;
			}
		}
		
		&:hover {
			text-decoration: none;
			color: #000;
			i {
				color: $color-green;
			}
		}
	}
	
	.row-title {
		position: relative;
		margin-top: -20rem;
		padding-top: 3rem;
		z-index: 2;
		
		&::after {
			position: absolute;
			display: block;
			content: '';
			background: #fff;
			left: 0;
			right: 0;
			top: 0;
			height: 20.5rem;
			z-index: -1;
		}
		
		.news-breadcrumb {
			font-size: (14rem / 16);
			padding-bottom: 3rem;
		}
		
		h1 {
			font-size: (48rem / 16);
			line-height: (56 / 48);
		}
	}
	
	.row-content,
	.row-image,
	.row-of-images,
	.row-date {
		position: relative;
		padding-top: 2em;
		margin-bottom: 1em;
		z-index: 2;
	}
	.row-content {
		margin-bottom: 0;
	}

	
	.row-image {
		img {
			border-top-left-radius: 40px; 
		}
	}
	
	.row-of-images {
		.row-of-image {
			min-height: 320px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		
		.col-md:first-of-type .row-of-image {
			border-top-left-radius: 40px; 
		}
		.col-md:last-of-type .row-of-image {
			border-bottom-right-radius: 40px; 
		}
	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		.banner-image {
			height: 240px;
		}
		.news-link {
			display: none;
		}
		.row-title {
			margin-top: 0;
			padding-top: 1.5rem;
			&::after {
				display: none;
			}
			.news-breadcrumb {
				padding-bottom: 1.5rem;
			}
		}
		.row-content,
		.row-image,
		.row-date {
			padding-top: 1.5em;
		}
	}
	
}