header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	
	background: #fff;
	box-shadow: 0 4px 16px rgba(0,0,0,0.1);
	font-size: 14px;
	padding: 20px 0;
	
	.header-logo {
		height: 50px;
	}
	
	.header-menu {
		text-align: right;
	}
	
	nav {
		line-height: 50px;
		a {
			display: inline-block;
			margin: 0 20px;
			color: #000;
			&:last-of-type {
				margin-right: 0;
			}
			
			&:hover {
				color: $color-blue;
				text-decoration: none;
			}
			
			&.current-menu-item {
				background: url('../images/heart-blue.png') center center no-repeat;
			}
		}
	}
	
	@include media-breakpoint-down(lg) {
		nav a {
			margin: 0 15px;
		}
	}
	
	@include media-breakpoint-down(md) {
		font-size: 12px;
		
		.header-logo {
			height: 42px;
		}
		
		nav a {
			margin: 0 8px;
		}
	}
	
	
	
	@include media-breakpoint-up(sm) {
		.header-mobile-controls {
			display: none;
		}
	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		padding: 15px 0;
		.header-menu {
			text-align: left;
			position: absolute;
			top: 70px;
			left: 0;
			right: 0;
			height: calc( 100vh - 70px );
			background: #fff url('../images/page-mobile-bg.png') center top repeat;
			height: 0;
			overflow-y: auto;
			transition: 0.5s;

			a {
				display: block;
				font-size: (28rem / 16);
				margin: 12px 0 12px 40px;
				
				&.current-menu-item {
					position: relative;
					background: none;
					z-index: 2;
					&::after{
						position: absolute;
						top: 0;
						left: 0;
						content: '';
						display: block;
						width: 54px;
						height: 45px;
						background: url('../images/heart-blue.svg') center center no-repeat;
						opacity: 0.5;
						z-index: -1;
					}
					
				}
			}
			.menu-phone {
				display: none;
			}
		}
		
		.header-logo {
			height: 40px;
		}
		
		.header-mobile-controls {
			position: absolute;
			top: 0;
			right: 0;
		}
		
		.mobile-menu-phone {
			display: inline-block;
			line-height: 70px;
			vertical-align: top;
			font-size: 1rem;
			color: $color-blue-dark;
		}
		
		.mobile-menu-toggle {
			position: relative;
			display: inline-block;
			padding: 22px;
			height: 70px;
			width: 70px;
			span {
				position: relative;
				transform-origin: 50% 50%;
				display: block;
				height: 5px;
				border-radius: 2.5px;
				background: $color-blue;
				transition: 0.5s;
			}
			span:first-of-type {
				background: $color-green;
				margin-bottom: 5px;
			}
			span:nth-of-type(2) {
				width: 80%;
				margin-bottom: 5px;
			}
			span:last-of-type {
				background: $color-blue-dark;
			}
		}
	}
}
@include media-breakpoint-down(md) {
	.mobile-menu-open {
		body {
			overflow: hidden;
		}
		
		header .mobile-menu-toggle {
			span:first-of-type {
				margin-bottom: 0;
				width: 110%;
				transform: translateY(10px) rotate(45deg);
			}
			span:nth-of-type(2) {
				width: 110%;
				margin-bottom: 0;
				transform: translateY(5px) rotate(-45deg);

			}
			span:last-of-type {
				opacity: 0;
			}
		}
		
		.header-menu {
			height: calc( 100vh - 70px );
		}
		
	}
}