/* Fonts */

@mixin font-alliased() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* ========================================================
 *	font-family: 'proxima-nova'
 * 	Weights: 400, 500, 700
 *	Styles: normal
 ========================================================*/

@import url("https://use.typekit.net/bhq0fng.css");

$font-proxima-nova: 'proxima-nova', Arial, Helvetica, sans-serif;

.proxima-nova {
	font-family: $font-proxima-nova;
	font-weight: 400;
}

@mixin font-proxima-nova($weight: 400) {
	font-family: $font-proxima-nova;
	font-weight: $weight;
	font-style: normal;
}

/* ========================================================
 *	Icon Font
 *	font-family: 'moreton-day-hospital'
 ========================================================*/

@mixin icon-font {
	font-family: "moreton-day-hospital" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
