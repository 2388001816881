/* SECTION Home intro */

.home-intro {
	position: relative;
	font-size: (14rem / 16);
	min-height: 730px;
	margin-bottom: -4.5rem;
	overflow-x: hidden;
	
	.title {
		width: 300px;
		height: 88px;
		margin-top: 207px;
		margin-bottom: 30px;
		background: url('../images/mdh-title.svg') center center no-repeat;	
	}
	
	.body {
		font-size: (19rem / 16);
		margin-bottom: 45px;
		max-width: 300px;
	}
	
	h3 {
		position: relative;
		font-size: (15rem / 16);
		font-weight: 700;
		&::before {
			position: absolute;
			content: '';
			background: url('../images/heart-green.png') center center no-repeat;	
			width: 33px;
			height: 26px;
			top: -11px;
			left: -14px;
			z-index: -1;
		}
	}
	
	.intro-image {
		position: absolute;
		top: -410px;
		right: -215px;
		border-radius: 50% 0 50% 50%;
		background: $color-blue-light;
		width: 1140px;
		height: 1140px;
		clip-path: inset(410px 215px 0 0);
		overflow: hidden;
		z-index: -2;
		
		//background-position: center center;
		background-repeat: no-repeat;
		//background-size: cover;
		
		background-position: left bottom;
		background-size: (1140px - 215);
	}
	
	@include media-breakpoint-down(lg) {
		min-height: 630px;
		.title {
			margin-top: (207px * 0.85);
			width: (300px * 0.85);
			height: (88px * 0.85);
		}
		
		.intro-image {
			top: -310px;
			width: 940px;
			height: 940px;
			clip-path: inset(310px 215px 0 0);
			background-size: auto (940px - 310);
		}
	}
	
	@include media-breakpoint-down(md) {
		.title {
			margin-top: (207px * 0.75);
			width: (300px * 0.75);
			height: (88px * 0.75);
		}
		
		.intro-image {
			top: -210px;
			width: 740px;
			height: 740px;
			clip-path: inset(210px 215px 0 0);
			background-size: auto (740px - 210);
		}
	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		min-height: 585px;
		color: #fff;
		font-size: (18rem / 16);
		
		a {
			color: #fff;
		}
		
		&::after {
			@include icon-font;
			content: "\63";
			position: absolute;
			bottom: 40px;
			left: 50%;
			font-size: 27px;
			transform: translateX(-50%);
		}
	
		.title {
			width: 208px;
			height: 61px;
			margin-top: 77px;
			margin-bottom: 16px;
			background: url('../images/mdh-title-white.svg') center center no-repeat;	
		}
		
		.body {
			font-size: (20rem / 16);
			margin-bottom: 0;
			max-width: auto;
		}
		
		h3 {
			font-size: 1rem;
			color: #fff;
			margin-bottom: 0;
			&::before {
				display: none;
			}
		}
		
		.intro-image {
			
			top: -315px;
			right: 50%;
			clip-path: inset(315px 0 0 0);
			width: 900px;
			height: 900px;
			transform: translateX(50%);
			
			background-position: center bottom;
			background-size: 900px;
			
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0,0,0,0.4);
			}
		}
	}
	
}
