/* ACF Google map */

.acf-map {
	height: 1000px;
	border-top-left-radius: 40px;
	
	// Mobile
	@include media-breakpoint-down(sm) {
		height: 525px;
		border-top-left-radius: 0;
	}
}

.contact-us {
	.acf-map {
		height: 500px;
	}
}