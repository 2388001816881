/* Section: Contact us */

.contact-us {
	
	.col-text {
		padding-left: (110rem / 16);
	}
	
	@include media-breakpoint-down(lg) {
		.col-text {
			padding-top: (60rem / 16);
			padding-left: (60rem / 16);
		}
	}
	@include media-breakpoint-down(md) {

		.col-text {
			padding-top: 0;
			padding-left: (40rem / 16);
		}

	}
	@include media-breakpoint-down(sm) {
		display: none;
	}
	
}