/* Footer */

footer {
	position: relative;
	background: $color-blue-light;
	padding: 64px 0;
	font-size: 12px;
	line-height: (20 / 12);
	margin-top: 10rem;
	
	a {
		color: #000;
		text-decoration: none;
	}
	
	nav {
		a {
			display: block;
		}
	}
	
	.footer-tools {
		padding-top: 40px;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				display: inline-block;
			}
		}
	}
	
	.flourish {
		position: absolute;
		background-size: contain;
	}
	.flourish-1 {
		bottom: 0;
		right: 0;
		background: url('../images/heart-green.svg') left bottom no-repeat;
		background-size: 490px;
		width: 380px;
		height: 270px;
		transform: rotate(90deg) translate(-55px, -55px);
	}
	.flourish-2 {
		bottom: 257px;
		right: 107px;
		background: url('../images/heart-blue.svg') left bottom no-repeat;
		width: 245px;
		height: 195px;
		transform: rotate(-90deg);
	}
	.flourish-3 {
		bottom: 80px;
		right: 315px;
		background: url('../images/heart-blue.svg') left bottom no-repeat;
		width: 80px;
		height: 60px;
		transform: rotate(90deg);
	}
	.flourish-4 {
		bottom: 240px;
		right: 330px;
		background: url('../images/heart-green.svg') left bottom no-repeat;
		width: 95px;
		height: 73px;
		transform: rotate(-90deg);
	}
	.flourish-5 {
		bottom: 130px;
		right: 430px;
		background: url('../images/heart-green.svg') left bottom no-repeat;
		width: 80px;
		height: 60px;
		transform: rotate(90deg);
	}
	
	@include media-breakpoint-down(sm) {
		text-align: center;
		font-size: 1rem;
		
		.footer-logo {
			margin-bottom: 20px;
		}
		.hours-row {
			.day { 
				text-align: left; 
				width: 110px; 
			}
			.hours { 
				text-align: right; 
			}
		}
		nav a {
			font-size: (19rem / 16);
			padding: 4px 0;
		}
		.footer-tools {
			padding-top: 20px;
			font-size: (14rem / 16);
			ul {
				li {
					display: block;
				}
			}
		}
		
		.flourish-1 {
			bottom: 0;
			right: 40px;
			background-position: center bottom;
			background-size: 180px;
			width: 180px;
			height: 90px;
			transform: rotate(90deg) translate(-155px, -85px);
		}
		.flourish-2 {
			bottom: '';
			top: -8px;
			right: -30px;
			background-size: 100px;
			background-position: center top;
			width: 100px;
			height: 40px;
		}
		.flourish-3 {
			bottom: 10px;
			right: '';
			left: -10px;
			background-position: left top;
			background-size: 120px;
			width: 80px;
			height: 60px;
		}
		.flourish-4 {
			bottom: '';
			right: '';
			top: 200px;
			left: 20px;
			width: 60px;
			height: 45px;
		}
		.flourish-5 {
			display: none;
		}
	}
}