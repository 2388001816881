/* Section: Image with text */

.image-with-text {
	
	.section-title-mobile {
		display: none;
	}
	
	.col-text {
		padding-top: (100rem / 16);
	}
	
	.col-image {
		img {
			border-radius: 40px 0 0 0;
		}
	}
	
	&.text-on-left {
		&::before {
			position: absolute;
			display: block;
			content: '';
			background: $color-green-light;
			top: 12rem;
			left: 0;
			right: calc(50% + 300px);
			border-radius: 0 200px 200px 0;
			height: 400px;
		}
		
		.col-text {
			padding-right: (110rem / 16);
		}
	}
	
	&.text-on-right {
		
		&::before {
			position: absolute;
			display: block;
			content: '';
			background: $color-blue-light;
			top: 12rem;
			right: 0;
			left: calc(50% + 300px);
			border-radius: 200px 0 0 200px;
			height: 400px;
		}
		
		.col-text {
			padding-left: (110rem / 16);
		}
	}
	
	@include media-breakpoint-down(lg) {
		&.text-on-left {
			&::before {
				top: 9rem;
				right: calc(50% + 250px);
			}
			.col-text {
				padding-top: (60rem / 16);
				padding-right: (60rem / 16);
			}
		}
		&.text-on-right {
			&::before {
				top: 9rem;
				left: calc(50% + 250px);
			}
			.col-text {
				padding-top: (60rem / 16);
				padding-left: (60rem / 16);
			}
		}
	}
	@include media-breakpoint-down(md) {
		&.text-on-left {
			&::before {
				top: 8rem;
				right: calc(50% + 120px);
			}
			.col-text {
				padding-top: 0;
				padding-right: (40rem / 16);
			}
		}
		&.text-on-right {
			&::before {
				top: 8rem;
				left: calc(50% + 120px);
			}
			.col-text {
				padding-top: 0;
				padding-left: (40rem / 16);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		
		.section-title-mobile {
			display: block;
		}
		.section-title {
			display: none;
		}
		
		&.text-on-left,
		&.text-on-right {
			&::before {
				
			}
			.col-text {
				padding: 24px 15px 0 15px;
			}
		}
		
		&.text-on-left {
			&::before {
				right: 20%;
				border-radius: 0 290px 290px 0;
				height: 580px;
			}
		}
		
		&.text-on-right {
			&::before {
				left: 20%;
				border-radius: 290px 0 0 290px;
				height: 580px;
			}
		}
		
		.order-12 { // put image back in order
		    -ms-flex-order: 0;
    		order: 0;
		}
		
		.col-image {
			padding: 0;
			img {
				border-radius: 0;
			}
		}
	}
	
}