/* CSS Document */

.learn-more {
	margin-top: 0.8rem;
    display: inline-block;
    font-size: (17rem / 16);
	
	&::after {
		@include icon-font;
		content: "\67";
		margin-left: 0.7em;
		color: $color-blue;
		transition: 0.3s;
	}
	&:hover {
		text-decoration: none;
		&::after {
			margin-left: 1.05em;
			color: $color-green;
		}
	}
}