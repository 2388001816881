/* Common section attributes */

.full-width-image,
.image-with-text,
.news-list,
.normal-content,
.row-of-image-links,
.contact-us,
.contact {
	position: relative;
	padding-top: 8rem;
	
	@include media-breakpoint-down(md) {
		padding-top: 6rem;
	}
}

// Larger title for first section on page
.first-on-page h2 {
	font-size: (48rem / 16);
	margin-bottom: 0.6em;
}