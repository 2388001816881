/* Section: Full-with image */

.row-of-image-links {
	
	.link-block {
		position: relative;
		display: block;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 360px;
		text-decoration: none;
		
		h3 {
			position: relative;
			color: #fff;
			text-align: center;
			margin-bottom: 0;
			font-weight: 500;
			font-size: (27rem / 16);
			z-index: 2;
		}
		
		&::before {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0,0,0,0.5);
			transition: 0.3s;
			z-index: 1;
		}
		
		&:hover {
			&::before {
				background: rgba(0,0,0,0.1);
			}
		}
		
	}
	
	.col-md:first-of-type .link-block { 
		border-top-left-radius: 40px; 
		&::before { border-top-left-radius: 40px; }
	}
	.col-md:last-of-type .link-block { 
		border-bottom-right-radius: 40px; 
		&::before { border-bottom-right-radius: 40px; }
	}
	
	// Mobile
	@include media-breakpoint-down(sm) {
		.text-center {
			text-align: left !important;
		}
		
		.col-md {
			width: 50%;
			padding: 6px;
			&:nth-child(2n) {
				padding-right: 15px;
			}
			&:nth-child(2n+1) {
				padding-left: 15px;
			}
		}
		.link-block {
			height: 190px;
			h3 {
				font-size: (20rem / 16);
			}
		}
	}
	
}