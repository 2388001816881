/* Typography */

body {
	@include font-proxima-nova();
	font-size: 16px;
	color: $color-text; 
}

h1, h2, h3 {
	font-weight: 700;
	@include font-alliased();
	margin-bottom: 1.5rem;
}

h1 {
	font-size: (48rem / 16);
	margin-bottom: 0.6em;
}

h2 {
	font-size: (34rem / 16);
}

h3 {
	font-size: (30rem / 16);
	color: $color-blue-dark;
}

a {
	color: $color-text;
	&:hover {
		color: $color-blue-dark;
	}
}


blockquote {
	border-left: 3px solid $color-blue;
	padding-left: 37px;
	font-weight: 500;
	font-size: (20rem / 16);
	
	cite {
		display: block;
		font-weight: 800;
		font-size: (17rem / 16);
		font-style: normal;
		margin-top: 1rem;
		
		.cite-image {
			width: 49px;
			height: 49px;
			border-radius: 50%;
			margin-right: 30px;
		}
	}
}

.col-text {
	line-height: (27 / 16);
	
	ul {
		margin-left: 0;
		padding-left: 0;
		list-style: none;
		li {
			padding-left: 2em;
			text-indent: -2em;
			&::before {
				@include icon-font;
				content: "\67";
				margin-right: 1em;
				color: $color-blue;
				font-size: 1em;
				line-height: 1rem;
			}
		}
	}	
}