/* CSS Document */

$color-blue: #00B1BD;
$color-green: #C1CE3A;

$color-blue-light: #E6EFF0;
$color-green-light: #F5F7DE;

$color-blue-dark: #006D71;


$color-text: #000;