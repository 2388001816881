/* Section: Normal content */

.normal-content {
	
	.col-text {
		
	}
	
	.col-image {
		img {
			margin-bottom: (52rem / 16);
			&:first-of-type { 
				border-top-left-radius: 40px; 
			}
			&:last-of-type { 
				border-bottom-right-radius: 40px; 
				margin-bottom: 0;
			}
		}
	}
	
	&.text-on-left {
		.col-image {
			padding-left: (100rem / 16);
		}
	}
		
	&.text-on-right {
		.col-image {
			padding-right: (100rem / 16);
		}
	}
	
	&.no-images {
		margin-bottom: -4.5rem;
	}
	
	@include breakpoint-lt(md) {
		&.text-on-left .col-image,
		&.text-on-right .col-image {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	
	
	
}